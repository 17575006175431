
.upload-btn {
  position: relative;

  input {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.icon {
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon-video {
  margin: 12px 10px 0;
  background: url("../../../../assets/icon/video.png") no-repeat;
  background-size: contain;
}
