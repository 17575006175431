
.scroll-button {
  position: absolute;
  bottom: 10px;
  right: 20px;
  width: 92px;
  height: 28px;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 12px -5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  z-index: 10;

  &-text {
    font-family: PingFangSC-Regular, system-ui;
    font-size: 10px;
    color: #147aff;
    margin-left: 3px;
  }
}
