
.messageSenderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
  height: fit-content;
  max-height: 200px;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-width: 1px 0 0 0;
}
