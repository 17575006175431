
.fastreply-bk {
    width: 100%;
    height: 100%;

    .fastreply-search {
        display: flex;

        .search-ipt {
            margin-right: 20px;
        }
    }
}

.fastreply-tabs {
    margin: 16px 0 10px;
}
.mess_info{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* 可选，当内容超出一行时，用省略号表示 */
}


::v-deep .el-form-item__content {
    display: flex;
    flex-wrap: nowrap;
}
