
.messageProductCard {
  min-width: 224px;
  max-width: 310px;
  background: #ffffff;
  border: 1px solid #dddddd;
  display: flex;
  padding: 12px;
  border-radius: var(--serverBubble--borderRadius, 10px)
    var(--serverBubble--borderRadius, 10px)
    var(--serverBubble--borderRadius, 10px) 0;

  .productImg {
    width: 86px;
    height: 86px;
  }

  .productCardInformation {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .productCardTitle {
      font-size: 12px;
      max-width: 165px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .productCardDescription {
      font-size: 16px;
      max-width: 165px;
      color: #ff6c2e;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
