
.messageActionsContainer {
  border-radius: 8px;
  padding: 6px 0;
  align-items: center;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  width: fit-content;
  background: white;
  position: absolute;
  z-index: 5;

  .action {
    padding: 4px 6px;
    font-size: 14px;
    width: 100px;
    display: flex;
    justify-content: space-between;
  }

  .action:hover {
    background: #abcffc;
  }

  .icon-msg-quote {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin-right: 2px;
    background: url("../../../../assets/icon/msg-qnote.svg") no-repeat;
    background-size: contain;
  }

  .icon-msg-xia {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin-right: 2px;
    background: url("../../../../assets/icon/msg-xia.svg") no-repeat;
    background-size: contain;
  }

  .icon-msg-copy {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin-right: 2px;
    background: url("../../../../assets/icon/msg-copy.svg") no-repeat;
    background-size: contain;
  }

  .icon-msg-revoke {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin-right: 2px;
    background: url("../../../../assets/icon/msg-revoke.svg") no-repeat;
    background-size: contain;
  }
}
