
.upload-demo {
    ::v-deep .el-upload .el-upload-dragger {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;width: 100px;
        border: none;
    }



    .upload-progress {
        width: 80px;
    }
}

.upload-name {
    width: 70px;
    height: 40px;
    font-size: 10px;
    line-height: 12px;
}

.name{

    display: -webkit-box;
    /* 设置为WebKit内核的弹性盒子模型 */
    -webkit-box-orient: vertical;
    /* 垂直排列 */
    -webkit-line-clamp: 3;
    /* 限制显示两行 */
    overflow: hidden;
    /* 隐藏超出范围的内容 */
    text-overflow: ellipsis;
    /* 使用省略号 */

}
.cuo{
    position: absolute;
    top: 0;
    right: 13px;
    width: 20px;
    height: 20px;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(50%,-50%);
    border-radius: 50%;
}
