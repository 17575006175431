
.richTextContainer {
  p {
    img {
      width: 100%;
      height: 100%;
      max-height: 300px;
      object-fit: contain;
    }
  }
}
