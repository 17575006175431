
.pageApp {
  display: flex;
  flex-direction: column;
}

.workspace {
  display: flex;
}

body {
  margin: 0;
}
