
.message-audio {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;

  .icon {
    margin: 0 4px;
  }

  .icon-voice {
    width: 16px;
    height: 16px;
    background: url("../../../../assets/icon/voice.png") no-repeat;
    background-size: contain;
  }

  .icon-voice-gif {
    width: 16px;
    height: 16px;
    background: url("../../../../assets/icon/voice.gif") no-repeat;
    background-size: contain;
  }

  audio {
    width: 0;
    height: 0;
  }
}

.reserve {
  flex-direction: row-reverse;
}
