
.quoteMessageContainer {
  display: flex;
  flex: 0 1 auto;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  align-items: center;
  line-height: 16px;
  box-sizing: border-box;
  min-width: 50px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

.quoteMessage {
  font-size: 12px;
  align-items: center;
  line-height: 16px;
  box-sizing: border-box;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

.cancelButton {
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
}
