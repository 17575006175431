
.rightIndex {
  width: 600px;
  max-height: calc(100vh - 60px) ;
  overflow: auto;
}

.demo-tabs {
  padding: 0 40px;
  width: 100%;
  box-sizing: border-box;
}

.demo-tabs>.el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
