
.headerContainer {
  display: flex;
  padding: 14px 20px;
  max-width: 100%;
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
  background-color: white;

  .visitorName {
    color: #333333;
    height: 20px;
    display: block;
    overflow: hidden;
    font-size: 14px;
    word-break: break-all;
    align-items: center;
    font-family: PingFangSC-Medium !important;
    text-overflow: ellipsis;
  }

  .visitorInfo {
    display: flex;
    margin-top: 5px;

    .startTime {
      color: #999;
      overflow: hidden;
      font-size: 12px;
      margin-top: 3px;
      align-items: center;
      font-family: PingFangSC-Regular !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      margin-right: 10px;
    }

    .serveTime {
      color: #999;
      overflow: hidden;
      font-size: 12px;
      margin-top: 3px;
      align-items: center;
      font-family: PingFangSC-Regular !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
    }

    .origin {
      color: #999;
      overflow: hidden;
      font-size: 12px;
      margin-top: 3px;
      align-items: center;
      font-family: PingFangSC-Regular !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      margin-left: 10px;
    }
  }
}
