
a {
  color: #679ce1;
}

.customMessage {
  font-size: 14px;

  h1 {
    font-size: 14px;
    color: #000000;
  }

  h1,
  a,
  p {
    font-size: 14px;
  }
}
