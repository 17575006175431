
.face-list-item::marker {
  content: none;
}

.face {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin: 12px 10px 0;
  background: url("../../../../assets/icon/face.png") no-repeat;
  background-size: contain;

  &-main {
    position: absolute;
    z-index: 999;
    width: 435px;
    height: 250px;
    top: -270px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  &-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-left: 10px;

    &-item {
      padding: 5px;
    }

    img {
      width: 30px;
    }

    .face-img {
      width: 60px;
    }
  }
}
