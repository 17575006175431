
.message-video {
  overflow: hidden;
  position: relative;
  video {
    max-width: min(calc(100vw - 180px), 300px);
    max-height: min(calc(100vw - 180px), 300px);
  }
}
.isWidth {
  width: 100%;
}

.isHeight {
  height: 100%;
}
