
.queuingListTitle {
  color: #999;
  margin: 5px 0 5px 20px;
  font-weight: 600;
  font-size: 12px;
}

.servingListTitle {
  color: rgba(21, 29, 50, 0.3);
  font-size: 12px;
  line-height: 24px;
  margin-left: 20px;
}

.endedListTitle {
  color: rgba(21, 29, 50, 0.3);
  font-size: 12px;
  line-height: 24px;
  margin-left: 20px;
}

.outerListContainer {
  overflow-y: scroll;
  height: calc(100vh - 170px);
}

.outerListContainer::-webkit-scrollbar {
  width: 0;
}
