
.transferItem {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  color: #333;
  width: 100%;
  cursor: pointer;
  height: 60px;
  margin: 0 10px;
  overflow: hidden;
  font-size: 12px; font-weight: 600;
  text-align: center;
  border: 1px solid #eee;
}
.TransferItemContainer {
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(54, 58, 80, 0.32);
  box-sizing: border-box;
  margin: 20px;
  padding: 20px;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  max-height: 500px;
}

.transferButton {
  justify-self: center;
}
.zhan{
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #ccc;
}
