
.text-img {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.text-box {
  display: inline-block;
  word-break: break-all;
  height: fit-content;

  a {
    color: #409eff;
    text-decoration: underline;
  }
}
