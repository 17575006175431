
.upload-btn {
  position: relative;
}

.icon {
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon-transfer {
  margin: 11px 10px 1px 10px;
  background: url("../../../../assets/icon/Transfer.svg");
  background-size: contain;
}
