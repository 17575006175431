
.selected {
  background: linear-gradient(90deg, #006eff 0%, #4696ff 100%);
  border-bottom: 1px solid #f7f7f7;
}

.userItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0 10px 10px;
  border-radius: 2px;
  width: 220px;

  .avatar-wrapper {
    position: relative;
    display: inline-block;
  }

  .dot {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    font-size: 12px;
    color: white;
    line-height: 20px;
    text-align: center;
  }

  .userItemAvtar {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 2px;
  }

  .userItemUserName {
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
    font-size: 14px;
    font-family: PingFangSC-Medium !important;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .userItemLastMessage {
    color: #8a8f97;
    overflow: hidden;
    font-size: 12px;
    margin-top: 3px;
    max-height: 17px;
    min-height: 17px;
    font-family: PingFangSC-Regular !important;
    line-height: 17px;
    height: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;
  }

  .userItemConncetTime {
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
    text-align: right;
    font-family: PingFangSC-Regular !important;
    font-weight: 400;
    line-height: 20px;
    height: 40px;
    align-self: flex-end;
    flex-shrink: 0;
    margin-right: 8px;
  }
}
