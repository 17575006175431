
.topActions {
  height: 56px;
  background: #2b303b;
  display: flex;
  align-items: center;

  .messageAvatar {
    margin-left: auto;
    margin-right: 10px;
    width: 36px;
    height: 36px;
  }
}

.action {
  padding: 4px 6px;
  font-size: 14px;
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.action:hover {
  background: #abcffc;
}
