
.OrderContainer {
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  background-color: white;
  border: 1px solid #999999;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  .GuideText {
    font-size: 18px;
    color: black;
    font-weight: bold;
    word-break: break-all;
    padding: 10px;
  }
  .OrderInfoContainer {
    display: flex;
    background: #f9f9f9;
    padding: 10px;
    .OrderName {
      font-size: 16px;
      font-weight: bold;
      word-break: break-all;
      color: black;
    }
    .OrderDesc {
      font-size: 14px;
      font-weight: bold;
      word-break: break-all;
      color: #999999;
    }
  }
}
