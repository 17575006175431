
.messageBubble {
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  margin: 0px;
  margin-top: 20px;
  line-height: 18px;
  word-break: break-all;
  background: #abcffc;
  position: relative;
}

.messageContainer {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 30px;
  padding: 0px 20px 10px 20px;
}

.messageContainerReverse {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  margin-top: 30px;
  padding: 0px 20px 10px 20px;
}

.selfMessage {
  border-top-right-radius: 0px;
  margin-right: 8px;
}

.otherMessage {
  border-top-left-radius: 0px;
  margin-left: 8px;
}

.messageAvatar {
  width: 36px;
  height: 36px;
}

.messageNick {
  font-size: 12px;
  box-sizing: border-box;
  width: 600px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.messageNickL {
  position: absolute;
  top: 0;
  left: 10px;
}

.messageNickR {
  position: absolute;
  top: 0;
  right: 10px;
  text-align: right;
}

.selfQuoteMessage {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  align-items: center;
  line-height: 16px;
  box-sizing: border-box;
  min-width: 50px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  margin-left: auto;
  margin-right: 60px;
  margin-bottom: 10px;
}

.otherQuoteMessage {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  align-items: center;
  line-height: 16px;
  box-sizing: border-box;
  min-width: 50px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  margin-right: auto;
  margin-left: 60px;
  margin-bottom: 10px;
}

@-webkit-keyframes reference {
  from {
    opacity: 1;
  }

  50% {
    background-color: #ff9c19;
  }

  to {
    opacity: 1;
  }
}

@keyframes reference {
  from {
    opacity: 1;
  }

  50% {
    background-color: #ff9c19;
  }

  to {
    opacity: 1;
  }
}
