
.upload-btn {
  position: relative;
}

.icon {
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon-rate {
  margin: 12px 10px 0;
  background: url("../../../../assets/icon/rate.svg");
  background-size: contain;
}
