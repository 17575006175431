
.upload-for {
    width: 100%;
    display: flex;
    flex-direction: column;

    .upload-name {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
            overflow: hidden;
            /* 隐藏超出范围的内容 */
            text-overflow: ellipsis;
            /* 使用省略号 */

        }

        .cuo {
            cursor:pointer;
        }
    }
}
