
.message-file {
  display: flex;
  flex-direction: column;

  .box {
    flex: 1;
    display: flex;
    cursor: pointer;
    align-items: center;
    z-index: 50;
    .message-file-content {
      overflow: hidden;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  progress {
    position: absolute; top: 0; left: 0;
    color: #abcffc;
    appearance: none;
    border-radius: 0.25rem;
    background: rgba(#efefef, 1);
    width: 100%;
    height: 100%;
    z-index: 0;
    margin: 0;
    &::-webkit-progress-value {
      background-color: #abcffc;
      border-radius: 0.25rem;
    }

    &::-webkit-progress-bar {
      border-radius: 0.25rem;
      background: rgba(#efefef, 1);
    }

    &::-moz-progress-bar {
      color:#abcffc;
      background: #abcffc;
      border-radius: 0.25rem;
    }
  }

  .fileName {
    width: 160px;
  }

  .fileSizeStatus {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .icon-files-word {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/word.png") no-repeat;
  }

  .icon-files-excel {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/excel.png") no-repeat;
  }

  .icon-files-image {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/image.png") no-repeat;
  }

  .icon-files-pdf {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/pdf.png") no-repeat;
  }

  .icon-files-ppt {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/ppt.png") no-repeat;
  }

  .icon-files-txt {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/txt.png") no-repeat;
  }

  .icon-files-unknown {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background: url("../../../../assets/images/unknown.png") no-repeat;
  }

  .icon-files-zip {
    background-size: 100% 100% !important;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    background-size: contain;
    background: url("../../../../assets/images/zip.png") no-repeat;
  }
}
