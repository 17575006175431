
.transferItem {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  color: #333;
  width: 100%;
  cursor: pointer;
  height: 60px;
  margin: 0 10px;
  overflow: hidden;
  font-size: 12px;
  text-align: center;
  border: 1px solid #eee;
}

// .dot {
//   position: absolute;
//   top: 5px;
//   left: 5px;
//   width: 5px;
//   height: 5px;
//   border-radius: 50%;
//   background-color: rgb(28, 201, 83);
//   font-size: 12px;
//   color: white;
//   line-height: 20px;
//   text-align: center;
// }

.selected {
  color: #006eff;
  // border: 1px solid #006eff;
  background: rgba(0, 110, 255, 0.1);
  font-family: PingFangSC-Medium !important;
}
