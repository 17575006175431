




.el-image-viewer__actions {
    padding: 0 58px 0 23px !important;
}


.viewerDownload {
    position: absolute;
    bottom: 43px;
    right: calc(50vw - 141px + 25px);
    z-index: 1;
    color: #fff;
    font-size: 20px;
    height: 20px;
    cursor: pointer;
}
