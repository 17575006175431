
.chatContainer {
  display: flex;
  max-width: 1100px;
  // width: 800px;
  flex: 1;
  border: 1px solid #e5e5e5;
  border-width: 0 1px 0 1px;
  height: calc(100vh - 56px) ;
  flex-direction: column;
  justify-content: space-between;
}

.transferContainer {
  padding-top: 10px;
  max-width: 800px;
  width: 800px;
  height: 100vh;
  border: 1px solid #e5e5e5;
  border-width: 0 1px 0 1px;
}

.emptyChatContainer {
  display: flex;
  max-width: 1100px;
  // width: 800px;
  flex: 1;
  border: 1px solid #e5e5e5;
  border-width: 0 1px 0 1px;
  height: calc(100vh - 56px) ;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.messageSenderContainerButton{
  height: 200px;
  max-height: 200px;
  width: 100%;
  display: flex;justify-content: center;align-items: center;
}
