
.outerContainer {
  position: relative;
  flex: 1;
  overflow: hidden hidden;
}

.messageListContainer {
  padding: 0;
  overflow: hidden auto;
  height: 100%;

  .messageContainer {
    flex-direction: column;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 30px;
    padding: 0px 20px 10px 20px;
  }

  .messageContainerReverse {
    flex-direction: column;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    margin-top: 30px;
    padding: 0px 20px 10px 20px;
  }

  .revokeMessage {
    text-align: center;
    color: #999;
    font-size: 12px;
    margin-top: 10px;
    padding-bottom: 10px;
  }
}
