
.message-image {
  overflow: hidden;
  position: relative;
  max-width: min(calc(100vw - 180px), 300px);
  max-height: min(calc(100vw - 180px), 300px);
  .message-img {
    max-width: min(calc(100vw - 180px), 300px);
    max-height: min(calc(100vw - 180px), 300px);
  }

  .progress {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    left: 0;
    top: 0;
    background: rgba(#000000, 0.5);
    display: flex;
    align-items: center;

    progress {
      color: #006eff;
      appearance: none;
      border-radius: 0.25rem;
      background: rgba(#ffffff, 1);
      width: 100%;
      height: 0.5rem;

      &::-webkit-progress-value {
        background-color: #006eff;
        border-radius: 0.25rem;
      }

      &::-webkit-progress-bar {
        border-radius: 0.25rem;
        background: rgba(#ffffff, 1);
      }

      &::-moz-progress-bar {
        color: #006eff;
        background: #006eff;
        border-radius: 0.25rem;
      }
    }
  }
}

.dialog {
  position: fixed;
  z-index: 12;
  width: 100vw;
  height: calc(100vh);
  background: rgba(#000000, 0.3);
  top: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  header {
    display: flex;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.49);
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px;
  }

  &-box {
    display: flex;
    flex: 1;
    max-height: 90%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }
}

.isWidth {
  max-height: 100%;
  max-width: 100%;
}

.isHeight {
  max-height: 100%;
  max-width: 100%;
}

.fileSizeStatus {
  position: absolute;
  right: 5px;
  bottom: 0;
}

